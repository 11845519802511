import React from "react";

import CtaSection from "../../components/ctaSection";
import useTranslations from "../../components/useTranslations";
import HelmetHeader from "../../components/helmetHeader";
import HeroUseCase from "../../components/useCase/hero";
import Testimonial from "../../components/useCase/testimonial";

const LesGeorgettes = ({ location }) => {
  const { clientCase, lang } = useTranslations();
  return (
    <div>
      <HelmetHeader
        location={location}
        lang={lang}
        title={clientCase.metas.title}
        metas={[{ name: "description", content: clientCase.metas.description }]}
      />
      <HeroUseCase />
      <Testimonial />
      <CtaSection />
    </div>
  );
};

export default LesGeorgettes;
