import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import DownloadCaseForm from "./form";
import useTranslations from "../useTranslations";
import reactStringReplace from "react-string-replace";

const HeroUseCase = () => {
  const { clientCase } = useTranslations();
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="block absolute inset-0" aria-hidden="true">
        <StaticImage
          className="absolute top-0 left-0 w-full h-full object-contain object-left"
          src="../../../static/images/use_cases/background/les_georgettes_bg.jpeg"
          alt="Logo altesse les georgettes"
          placeholder="blurred"
        />
        <div className="absolute h-full w-full top-0 left-0 bg-white bg-opacity-80" />
      </div>
      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mt-16">
          <div className="mx-auto max-w-6xl px-4 sm:px-8 lg:px-16">
            <div className="lg:grid lg:grid-cols-12 lg:gap-6">
              <div className="text-left md:max-w-2xl md:mx-auto lg:col-span-6 lg:flex lg:items-center">
                <div>
                  <div className="flex w-full justify-center">
                    <StaticImage
                      className="w-48"
                      src="../../../static/images/logo/altesse_400_133.png"
                      alt="Logo altesse les georgettes"
                      placeholder="blurred"
                    />
                  </div>
                  <h1 className="mt-8 text-3xl tracking-tight font-bold text-gray-900 sm:leading-none">
                    {clientCase.hero.title}{" "}
                    <span className="text-2xl font-normal text-gray-900">
                      {clientCase.hero.subtitle}
                    </span>
                  </h1>
                  <p className="mt-8 font-normal text-gray-600 text-md">
                    {clientCase.hero.description}
                    <br />
                    <br />
                    {clientCase.hero.list.title}
                    <br />
                    <br />
                    <ul className="list-disc ml-3">
                      {clientCase.hero.list.items.map((item, index) => (
                        <li key={index}>{item.item}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6 justify-center lg:justify-self-end">
                <div className="bg-white sm:max-w-md sm:w-full mx-auto sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div className="mt-1">
                      <DownloadCaseForm />
                    </div>
                  </div>
                </div>
                <div className="px-2 mt-3 sm:max-w-md mx-auto sm:w-full sm:rounded-lg sm:overflow-hidden">
                  <p className="text-xs leading-5 text-gray-500 text-justify">
                    {reactStringReplace(
                      clientCase.form.terms.content,
                      "{{linkTerms}}",
                      () => (
                        <a
                          href={clientCase.form.terms.linkTerms}
                          target="_blank"
                          className="font-medium text-cyan-700 hover:underline"
                        >
                          {clientCase.form.terms.textTerms}
                        </a>
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default HeroUseCase;
