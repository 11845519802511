import React, { useMemo, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import reactStringReplace from "react-string-replace";
import useTranslations from "../useTranslations";

import { sendEvent } from "../../utils/googleMeasurementProtocol";
import CalendarIllu from "../../../static/images/bookDemo/calendar.svg";
import TranslateIllu from "../../../static/images/bookDemo/translate.svg";
import LocalizedLink from "../localizedLink";

export default function DownloadCaseForm() {
  const [isSent, setIsSent] = useState(false);
  const { clientCase, lang } = useTranslations();

  const Schema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(clientCase.form.requiredField),
        lastName: Yup.string().required(clientCase.form.requiredField),
        email: Yup.string()
          .email(clientCase.form.invalidEmail)
          .required(clientCase.form.requiredField),
      }),
    [clientCase]
  );
  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          template: "lesgeorgettes",
          language: lang,
        }}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          window.dataLayer.push({
            event: "download_case",
            user_data: {
              email_address: values.email,
              address: {
                first_name: values.firstName,
                last_name: values.lastName,
              },
            },
            template: values.template,
            language: values.language,
          });
          setSubmitting(false);
          setIsSent(true);
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched }) =>
          isSent ? (
            <div>
              <h1 className="mb-1 text-xl mx-auto font-bold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-3xl">
                {clientCase.form.success.title}
              </h1>

              <p className="mt-6 text-md font-normal text-gray-600">
                {reactStringReplace(
                  clientCase.form.success.content,
                  "<br/>",
                  () => (
                    <br />
                  )
                )}{" "}
              </p>
            </div>
          ) : (
            <Form className="flex flex-col space-y-4">
              <h1 className="mb-1 mx-auto text-xl font-bold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-3xl">
                {clientCase.form.title}
              </h1>
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {clientCase.form.fields.firstName} *
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                    placeholder="John"
                  />
                </div>
                {errors.firstName && touched.firstName && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.firstName}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {clientCase.form.fields.lastName} *
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                    placeholder="Doe"
                  />
                </div>
                {errors.lastName && touched.lastName && (
                  <p className="mt-1 text-sm text-red-600">{errors.lastName}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {clientCase.form.fields.email} *
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    placeholder="you@company.com"
                  />
                </div>
                {errors.email && touched.email && (
                  <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                )}
              </div>

              <div>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="mr-auto max-w-10 mt-4 block rounded-md border border-transparent px-5 py-3 bg-cyan-600 text-base font-medium text-white shadow hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cyan-700 sm:px-10 disabled:opacity-50 "
                >
                  {clientCase.form.submitButtonLabel}
                </button>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  );
}
