import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import useTranslations from "../useTranslations";

const Testimonial = () => {
  const { clientCase } = useTranslations();
  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <div className="mx-auto h-10 w-32">
            <StaticImage
              className="h-10 w-32"
              src="../../../static/images/logo/altesse_400_133.png"
              alt="Logo altesse les georgettes"
              placeholder="blurred"
            />
          </div>
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>&ldquo;{clientCase.testimonial.content}&rdquo;</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <StaticImage
                    className="mx-auto h-10 w-10 rounded-full"
                    src="../../../static/images/use_cases/users/hassen_hammeche.jpeg"
                    alt={clientCase.testimonial.author}
                    placeholder="blurred"
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {clientCase.testimonial.author}
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    {clientCase.testimonial.job},{" "}
                    {clientCase.testimonial.company}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
